<template>
    <v-card>
        <v-toolbar
                dark
                color="primary"
        >
            <v-toolbar-title>{{ formTitle }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
                <v-btn v-if="editedItem.id" dark text @click="save">{{ trans('buttons.common.save') }}</v-btn>
                <v-btn v-else dark text @click="save">{{ trans('buttons.common.create') }}</v-btn>
                <v-btn
                        icon
                        dark
                        @click="$emit('close')"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar-items>
        </v-toolbar>

        <v-card-text>
            <v-container>
                <v-row>
                    <v-col cols="12" md="6" lg="8">
                        <v-text-field
                            v-model="editedItem.label"
                            :label="trans('fields.common.label')"
                            :error-messages="errors.label"
                            @input="clearError('label')"
                        ></v-text-field>
                        <v-select
                            :items="options.offerTypes"
                            :label="trans('fields.common.offerTypes')"
                            :multiple="true"
                            v-model="editedItem.offerTypesArr"
                            @change="offerTypesChange"
                            :error-messages="errors.offerTypesArr"
                            @input="clearError('offerTypesArr')"
                        ></v-select>
                        <v-select
                                :items="options.landingDesignScopes"
                                :label="trans('fields.common.scope')"
                                v-model="editedItem.scope"
                                :error-messages="errors.scope"
                                @input="clearError('scope')"
                        ></v-select>
                        <user-autocomplete
                                v-if="editedItem.scope"
                                v-model="editedItem.user_id"
                                :label="trans('fields.common.user')"
                                :error-messages="errors.user_id"
                                @input="clearError('user_id')"
                        ></user-autocomplete>

                        <v-subheader>{{ trans('pages.landingDesign.layout') }}</v-subheader>
                        <v-file-input
                                show-size
                                placeholder="ImportFiles"
                                multiple
                                chips
                                clearable
                                @change="contentImport"
                        ></v-file-input>

                        <v-expansion-panels :accordion="true" :multiple="true">
                            <v-expansion-panel>
                                <v-expansion-panel-header
                                    :disable-icon-rotate="!!errors.html"
                                >
                                    HTML
                                    <template v-slot:actions v-if="errors.html">
                                        <v-icon color="error">mdi-alert-circle</v-icon>
                                    </template>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-alert
                                        v-if="errors.html"
                                        border="bottom"
                                        color="red"
                                        class="mt-4"
                                        dark
                                    >
                                        {{ errors.html.join('; ') }}
                                    </v-alert>
                                    <editor
                                        v-model="editedItem.html"
                                        @init="editorInit"
                                        lang="html"
                                        theme="monokai"
                                        height="800"
                                        @input="clearError('html')"
                                    ></editor>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel
                                v-for="(itemTemplate, offerTypeId) in editedItem.itemsTemplates"
                                :key="'tplTitle-'+offerTypeId"
                            >
                                <v-expansion-panel-header
                                    :disable-icon-rotate="!!errors['itemsTemplates.' + offerTypeId]"
                                >
                                    {{ offerTypesKeyed[offerTypeId].text }}
                                    <template v-slot:actions v-if="errors['itemsTemplates.' + offerTypeId]">
                                        <v-icon color="error">mdi-alert-circle</v-icon>
                                    </template>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-alert
                                        v-if="errors['itemsTemplates.' + offerTypeId]"
                                        border="bottom"
                                        color="red"
                                        class="mt-4"
                                        dark
                                    >
                                        {{ errors['itemsTemplates.' + offerTypeId].join('; ') }}
                                    </v-alert>
                                    <editor
                                        v-model="editedItem.itemsTemplates[offerTypeId]"
                                        @init="editorInit"
                                        lang="html"
                                        theme="monokai"
                                        height="600"
                                        @input="clearError('itemsTemplates.' + offerTypeId)"
                                    ></editor>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel>
                                <v-expansion-panel-header
                                    :disable-icon-rotate="!!errors.tokens"
                                >
                                    Tokens
                                    <template v-slot:actions v-if="errors.tokens">
                                        <v-icon color="error">mdi-alert-circle</v-icon>
                                    </template>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-alert
                                        v-if="errors.tokens"
                                        border="bottom"
                                        color="red"
                                        class="mt-4"
                                        dark
                                    >
                                        {{ errors.tokens.join('; ') }}
                                    </v-alert>
                                    <editor
                                        v-model="editedItem.tokens"
                                        @init="editorInit"
                                        lang="json"
                                        theme="monokai"
                                        height="600"
                                        @input="clearError('tokens')"
                                    ></editor>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel>
                                <v-expansion-panel-header
                                        :disable-icon-rotate="!!errors.settings"
                                >
                                    Settings
                                    <template v-slot:actions v-if="errors.settings">
                                        <v-icon color="error">mdi-alert-circle</v-icon>
                                    </template>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-alert
                                            v-if="errors.settings"
                                            border="bottom"
                                            color="red"
                                            class="mt-4"
                                            dark
                                    >
                                        {{ errors.settings.join('; ') }}
                                    </v-alert>
                                    <editor
                                            v-model="editedItem.settings"
                                            @init="editorInit"
                                            lang="json"
                                            theme="monokai"
                                            height="600"
                                            @input="clearError('settings')"
                                    ></editor>
                                </v-expansion-panel-content>
                            </v-expansion-panel>

                        </v-expansion-panels>
                    </v-col>
                    <v-col cols="12" md="6" lg="4">
                        <v-file-input
                            show-size
                            accept="image/png, image/jpeg"
                            :placeholder="trans('fields.common.designImage')"
                            @change="imageChange"
                            :loading="imageLoading"
                        ></v-file-input>
                        <v-img
                            v-if="editedItem && editedItem.imageUrl"
                            max-width="400px"
                            max-height="623px"
                            :aspect-ratio="400/623"
                            :src="editedItem.imageUrl"
                            position="top"
                            v-model="imageFile"
                        ></v-img>

                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">

                    </v-col>
                </v-row>
            </v-container>
        </v-card-text>
        <v-card-text v-if="editedItem.id">
            <v-container>
                <v-row>
                    <v-col cols="12">
                        <v-subheader class="mt-4">{{ trans('pages.landingDesign.translations') }}</v-subheader>
                        <landing-design-data-table :landing_design_id="editedItem.id"></landing-design-data-table>
                    </v-col>
                </v-row>
            </v-container>
        </v-card-text>

        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="$emit('close')">{{ trans('buttons.common.cancel') }}</v-btn>
            <v-btn v-if="editedItem.id" color="blue darken-1" text @click="save">{{ trans('buttons.common.save') }}</v-btn>
            <v-btn v-else color="blue darken-1" text @click="save">{{ trans('buttons.common.create') }}</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    import formValidate from "@/mixins/formValidate";
    import UserAutocomplete from "@/components/admin/user/UserAutocomplete";
    export default {
        name: "LandingDesignForm",
        mixins: [formValidate],
        components: {
            UserAutocomplete,
            editor: require('vue2-ace-editor'),
            LandingDesignDataTable: () => import('@/components/admin/landingDesignData/LandingDesignDataTable'),
        },
        props: {
            editedItem: {
                type: Object,
            },
            options: {
                type: Object,
            }
        },
        data() {
            return {
                imageFile: null,
                imageLoading: false,
                editorConfig: {
                    useBeautifyCode: true,
                }
            }
        },
        created(){
            this.editedItem.settings = JSON.stringify(JSON.parse(this.editedItem.settings), null, '\t');
            this.editedItem.tokens = JSON.stringify(JSON.parse(this.editedItem.tokens), null, '\t');
        },
        methods: {
            editorInit: function (editor) {
                require('brace/ext/language_tools') //language extension prerequsite...
                require('brace/mode/html')
                require('brace/mode/json')
                require('brace/mode/javascript')    //language
                require('brace/theme/monokai')
                editor.setOptions({
                    fontSize: "14pt",
                });
                editor.getSession().setUseWrapMode(true);
                console.log(editor.getSession().$modeId);
                console.log(editor.getValue());
            },
            offerTypesChange(){
                let itemsTemplates = _.pick(this.editedItem.itemsTemplates, this.editedItem.offerTypesArr);
                _.each(this.editedItem.offerTypesArr, (item) => {
                    if (!itemsTemplates[item]){
                        itemsTemplates[item] = "";
                    }
                })
                this.editedItem.itemsTemplates = itemsTemplates;
            },
            imageChange(file){
                if (file){
                    this.imageLoading = true;
                    let reader = new FileReader();
                    reader.onload = e => {
                        console.log(e);
                        this.editedItem.imageUrl = e.target.result;
                    };
                    reader.readAsDataURL(file);
                    let formData = new FormData();
                    formData.append('file', file);
                    axios.post( this.route('admin.landingDesigns.uploadImage'),
                        formData,
                        {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }
                        }
                    ).then((response) => {
                        this.editedItem.image_path = response.data.image_path;
                    }).catch(() => {
                        console.log('FAILURE!!');
                    }).then( () => {
                        this.imageLoading = false;
                    });
                } else {
                    this.editedItem.imageUrl = null;
                }
            },

            contentImport(files){
                console.log(files)
                const fields = {
                    'html.html': 'html',
                    '1_loan.html': 'itemsTemplates.1',
                    '2_credit.html': 'itemsTemplates.2',
                    '3_debitCard.html': 'itemsTemplates.3',
                    '4_creditCard.html': 'itemsTemplates.4',
                    '5_installmentCard.html': 'itemsTemplates.5',
                    '6_autoLoan.html': 'itemsTemplates.6',
                    'settings.json': 'settings',
                    'tokens.json': 'tokens',

                }
                if (files && files.length){
                    _.each(files, (file) => {
                        let field = fields[file.name];
                        if (field){
                            let reader = new FileReader();
                            reader.onload = e => {
                                console.log(e);
                                _.set(this.editedItem, field, e.target.result)
                            };
                            reader.readAsText(file);
                            // reader.readAsDataURL(file);
                        }
                    })
                }
            },

            save() {
                if (!this.editedItem.id) {
                    axios.post(this.route('admin.landingDesigns.store'), this.editedItem).then(() => {
                        console.log('save success');
                        this.$emit('saved');
                    }).catch( (err) => {
                        this.errors = err.response.data.errors;
                        console.log(err.response.data);
                    })
                } else {
                    axios.post(this.route('admin.landingDesigns.update', this.editedItem.id), this.editedItem).then(() => {
                        console.log('save success');
                        this.$emit('saved');
                    }).catch( (err) => {
                        this.errors = err.response.data.errors;
                        console.log(err.response.data);
                    })
                }
            },
        },
        computed: {
            formTitle() {
                return !this.editedItem.id
                    ? this.trans('pages.landingDesign.newDialogTitle')
                    : this.trans('pages.landingDesign.editDialogTitle');
            },
            offerTypesKeyed(){
                return _.keyBy(this.options.offerTypes, 'value');
            }
        }
    }
</script>
